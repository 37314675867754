const masks = {
  customObjectId: {
    blocks: [10],
    numericOnly: false,
  },
  customAccount: {
    blocks: [20],
    numericOnly: true,
  },
  customAccountThreeDigits: {
    blocks: [3],
    numericOnly: true,
  },
  customAccountFiveDigits: {
    blocks: [5],
    numericOnly: true,
  },
  customCURP: {
    blocks: [18],
    numericOnly: false,
  },
  customCP: {
    blocks: [5],
    numericOnly: true,
  },
  customPhone: {
    blocks: [10],
    numericOnly: true,
  },
  money: {
    numeral: true,
    numeralThousandsGroupStyle: "thousand",
    prefix: "$ ",
    numeralDecimalScale: 5,
  },
};

export default masks;
