/**
 *
 * @desc opens a dialog to confirm a delete action inside of a component
 * @param type the type of the dialog
 * @param functionOnConfirm the function tht will be run after the confirmation success
 * @param name the name of the object we want to delete
 * @param deleteMessage the delete message that the user will see
 */
function openConfirmationModalError(
  type,
  functionOnConfirm,
  name,
  deleteMessage
) {
  this.$buefy.dialog.confirm({
    title: `Borrar ${name}`,
    message: `¿Estas seguro que deseas <b>borrar</b> ${deleteMessage}? Esta acción no puede ser revertida.`,
    confirmText: `Borrar ${name}`,
    type: "is-danger",
    hasIcon: true,
    onConfirm: () => {
      functionOnConfirm();
    },
    cancelText: "cancelar",
  });
}

/**
 *
 * @desc opens a dialog to confirm a delete action inside of a component
 * @param type the type of the dialog
 * @param functionOnConfirm the function tht will be run after the confirmation success
 * @param dangerMessage the delete message that the user will see
 */
function openConfirmationModalDanger(type, functionOnConfirm, dangerMessage) {
  this.$buefy.dialog.confirm({
    title: `${dangerMessage}`,
    message: `¿Estas seguro que deseas ${dangerMessage}? Esta acción no puede ser revertida.`,
    confirmText: `${dangerMessage}`,
    type: "is-danger",
    hasIcon: true,
    onConfirm: () => {
      functionOnConfirm();
    },
    cancelText: "cancelar",
  });
}

/**
 *
 * @desc opens a dialog to confirm a success or generic action inside of a component
 * @param type the type of the dialog
 * @param functionOnConfirm the function tht will be run after the confirmation success
 * @param name the name of the object we want to delete
 */
function openConfirmationModalSucces(type, functionOnConfirm, name) {
  this.$buefy.dialog.confirm({
    title: `${name}`,
    message: `¿Estas seguro que deseas <b>${name}</b>? Esta acción no puede ser revertida.`,
    confirmText: `${name}`,
    type: "is-success",
    hasIcon: true,
    onConfirm: () => {
      functionOnConfirm();
    },
    cancelText: "cancelar",
  });
}

/**
 *
 * @desc In line fills object keys with keys from other object
 * @param objectInformation the object used to fill
 * @param formInformation the object filled
 */
function fillObjectForm(objectInformation, formInformation) {
  // Move trough all the articleInformation keys
  for (const key of Object.keys(objectInformation)) {
    // Check if object key is not null or undefined
    if (
      objectInformation[key] !== null &&
      objectInformation[key] !== undefined &&
      formInformation[key] !== undefined &&
      formInformation[key] !== null
    ) {
      formInformation[key] = objectInformation[key];
    }
  }
}

export {
  openConfirmationModalError,
  openConfirmationModalDanger,
  openConfirmationModalSucces,
  fillObjectForm,
};
