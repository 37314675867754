/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!bankInformation">
      Agregar banco
    </h1>

    <div v-if="!bankInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <div class="manual-bank-account">
      <h3 class="form-division">Información general</h3>

      <b-field grouped class="special-margin">
        <b-field
          expanded
          label-position="inside"
          :type="{ 'is-danger': newBankRequiredErrors.CLAVE }"
          :message="{ 'La clave no es valida': newBankRequiredErrors.CLAVE }"
        >
          <template slot="label">
            Clave (Requerido)
            <b-tooltip
              label="Clave interna para identificación del banco"
              multilined
            >
              <b-icon
                size="is-small"
                icon="help-circle-outline"
                custom-class="input-help-icon"
              ></b-icon>
            </b-tooltip>
          </template>
          <b-input
            :disabled="bankInformation"
            placeholder="Ejemplo: 12345HS"
            expanded
            icon="account-check-outline"
            required
            v-model="newBank.CLAVE"
          />
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Nombre (Requerido)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newBankRequiredErrors.NOMBRE }"
          :message="{ 'El nombre no es valido': newBankRequiredErrors.NOMBRE }"
        >
          <b-input
            placeholder="Ejemplo: HSBC"
            expanded
            icon="office-building"
            required
            v-model="newBank.NOMBRE"
          />
        </b-field>
        <b-field
          label="RFC (Opcional)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newBankRequiredErrors.RFC }"
          :message="{ 'El RFC no es valido': newBankRequiredErrors.RFC }"
        >
          <b-input
            placeholder="Ejemplo: CAPV841211G54"
            expanded
            icon="alpha-r-circle-outline"
            v-model="newBank.RFC"
          />
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Status (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
          :type="{ 'is-danger': newBankRequiredErrors.STATUS }"
          :message="{ 'El status no es valido': newBankRequiredErrors.STATUS }"
        >
          <b-select
            placeholder="Ejemplo: Status"
            expanded
            icon="clipboard-check"
            v-model="newBank.STATUS"
          >
            <option value="AC">Activo</option>
            <option value="CA">Cancelado</option>
          </b-select>
        </b-field>
        <b-field label="Clave Sat (Opcional)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: 012"
            expanded
            icon="folder-key-outline"
            v-model="newBank.CLAVESAT"
          />
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls" v-if="bankInformation">
      <b-button type="is-success" @click="saveBank(1)">
        Actualizar banco
      </b-button>
    </div>

    <div class="add-global-controls" v-if="!bankInformation">
      <b-button type="is-success" @click="saveBank(0)">
        Agregar banco
      </b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button">
        Regresar
      </b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import Cleave from "cleave.js";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";
import Masks from "../../../data/cleave-masks";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddBankModal",
  props: ["bankInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      moment: moment,
      newBank: {
        CLAVE: "",
        NOMBRE: "",
        RFC: "",
        STATUS: "AC",
        CLAVESAT: "",
      },
      newBankRequiredErrors: {
        CLAVE: false,
        NOMBRE: false,
        STATUS: false,
      },
      masks: Masks,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    // Fill the prices list information in form fields
    if (this.bankInformation) {
      // Fill the form with received information
      fillObjectForm(this.bankInformation, this.newBank);
      this.newBank._id = this.bankInformation._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveBank(type) {
      try {
        this.articleRequiredErrors = validateFields(
          this.newBank,
          this.newBankRequiredErrors
        );
        if (checkValidationErrors(this.newBankRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEBANK" : "EDITBANKS",
            this.newBank
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el banco",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar el banco en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el banco en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el banco en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newBank = {
        CLAVE: "",
        NOMBRE: "",
        RFC: "",
        STATUS: "AC",
        CLAVESAT: "",
      };
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
